<template>
  <move-transaction-to-saving-summary :transaction="transaction"
                          :from-category="oneTimeSaving"
                          :to-category="fixedSaving"
                          title="ההפקדה לחיסכון תעבור"
                          @save.stop="onSave"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import cashflowViewConsts from '@/constants/cashflow-view';
import SegmentTracker from '@/mixins/SegmentTracker';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';

export default {
  name: 'MoveOneTimeSavingTransactionToFixedSaving',
  components: {
    MoveTransactionToSavingSummary,
  },
  props: {
    transaction: { type: Object, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveOneTimeSavingTransactionToFixedSaving_', { transaction: this.transaction });
    this.track('Entered');
  },
  computed: {
    ...mapGetters('cashflowView', ['oneTimeSavingCategory']),
    ...mapGetters('editTransaction', ['ENRICHMENT_INPUT_TYPES']),
    fixedSaving() {
      return {
        cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_SAVING,
        pluralLabel: 'קבועות',
      };
    },
    oneTimeSaving() {
      return {
        cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
        pluralLabel: 'הפקדות חד-פעמיות',
      };
    },
  },
  methods: {
    ...mapActions('editCashflow', ['moveExpenseToFixed']),

    onSave() {
      this.track('SaveClicked');
      this.moveExpenseToFixed({
        transactionId: this.transaction.transactionId,
        expense: cashflowViewConsts.FIXED_SAVING_CATEGORY_NAME,
      });

      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: { ...this.transaction, isSaving: true },
          targetCategory: {
            pluralLabel: 'הפקדות קבועות לחיסכון',
          },
        },
      });
    },
  },
};
</script>
