<template>
  <content-loader />
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import ContentLoader from '@/base-components/ContentLoader';
import CustomersApi from '@/api/CustomersApi';
import Segment from '@/Segment';

export default {
  name: 'SaveUTMParamsAndRedirect',
  components: {
    ContentLoader,
  },
  async created() {
    Segment.trackUserGot('SaveUTMParamsAndRedirectEntered');
    await this.saveUTMParams();
    this.redirectToNextPage();
  },
  computed: {
    ...mapGetters('signup', ['amplitudeUtmParams']),
    ...mapState('signup', ['utmParams']),
    ...mapState('session', ['customerId']),
  },
  methods: {
    ...mapActions('signup', ['fetchUtmParamsFromRoute']),
    identifyCustomer() {
      Segment.identifyCustomer(this.customerId, {
        ...this.amplitudeUtmParams,
      });
    },
    async saveUTMParams() {
      this.fetchUtmParamsFromRoute();
      if (!_.isEmpty(this.utmParams)) {
        this.identifyCustomer();
        await CustomersApi.updateCustomerIntake({ intake: { utmParams: this.utmParams } });
      }
    },
    redirectToNextPage() {
      const { authRedirectTo, ...query } = this.$route.query;
      router.push({ path: `/${authRedirectTo || 'sr'}`, query });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
