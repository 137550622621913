<template>
  <story-viewer
    v-if="!appLoading"
    class="story-selector"
    :story-name="name"
    :pages="pages"
  ></story-viewer>
</template>
<script>

import Segment from '@/Segment';
import StoryViewer from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StoryViewer';
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';

export default {
  name: 'StorySelector',
  components: {
    StoryViewer,
  },
  props: {
    storyName: {
      type: String,
      required: true,
    },
  },
  async created() {
    this.startLoading('story');
    const story = await this.fetchStory(this.storyName);
    this.doneLoading('story');
    if (story === '') {
      Segment.trackUserGot('NoSuchStory', this.storyName);
      await router.push({ path: '/sr', query: this.$route.query });
      return;
    }
    Segment.trackUserGot('StorySelected', this.segmentProps);
  },
  computed: {
    ...mapGetters('loader', ['appLoading']),
    ...mapGetters('story', ['name', 'pages']),
    segmentProps() {
      return {
        storyName: this.name,
        ...this.segmentExtraProps,
      };
    },
  },
  methods: {
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    ...mapActions('story', ['fetchStory']),
  },
};

</script>

<style lang="scss" scoped>
</style>
