<template>
  <move-transaction-to-saving-summary :transaction="transaction"
                          :from-category="oneTimeSaving"
                          :to-category="variableCategory"
                          @save.stop="onSave"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import cashflowViewConsts from '@/constants/cashflow-view';
import SegmentTracker from '@/mixins/SegmentTracker';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';

export default {
  name: 'MoveOneTimeSavingToVariable',
  components: {
    MoveTransactionToSavingSummary,
  },
  props: {
    transaction: { type: Object, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveOneTimeSavingToVariable_', { transaction: this.transaction });
    this.track('Entered');
  },
  computed: {
    ...mapGetters('cashflowView', ['oneTimeSavingCategory']),
    ...mapGetters('editTransaction', ['ENRICHMENT_INPUT_TYPES']),
    oneTimeSaving() {
      return {
        cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
        pluralLabel: 'הפקדות חד-פעמיות',
      };
    },
  },
  methods: {
    ...mapActions('editCashflow', ['moveTransaction']),

    onSave() {
      this.track('SaveClicked');
      this.moveTransaction({
        originalCategory: {
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
          categoryId: this.oneTimeSavingCategory.categoryId,
        },
        selectedCategory: this.variableCategory,
        transaction: this.transaction,
        categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
        valueForPredicate: this.transaction.transactionId,
      });

      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: { ...this.transaction, isSaving: false },
          targetCategory: this.variableCategory,
        },
      });
    },
  },
};
</script>
